import React, { useEffect, lazy, Suspense } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'

import Navbar from './components/NavBar/Nav'
import Footer from './components/Footer/Footer'

import { CssBaseline } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { selectProfile, setProfile } from './redux/profile/profileSlice'
import { setMessages } from './redux/message/messageSlice'

import { initSocketEvents } from './old_socket'
import { connectSocket, initializeSocketListeners } from './socket'

import { API_URL, hasPermission } from './utils'
import ProfilePage from './pages/Profile/ProfilePage'
import StorePage from './pages/StorePage/Page'
import { ToastContainer } from 'react-toastify'

import GiveawayPage from './pages/Giveaways'
import HighlightPage from './pages/HighlightPage/HighlightPage'
import CookieConsentBanner from './components/CookieConsentBanner'

import ToastQuery from './components/Toasts/ToastQuery'
import HomePage from './pages/HomePage/HomePage'
import LeaderboardPage from './pages/LeaderboardPage'
import { defaultTheme } from './themes'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
import ModalQuery from './components/ModalQuery'


import BattlePage from './pages/BattlePage/BattlePage'
import BattleHistoryPage from './pages/BattlePage/BattleHistoryPage'
import { Permission } from './shared/user.interface'

import BattleListPage from './pages/BattlePage/BattleListPage'

import ClaimPromocode from './components/NavBar/ClaimPromocode'
import Loading from './components/Loading'
import RafflePage from './pages/Raffle/RafflePage'
import BottomNavbar from './components/NavBar/BottomNavbar'
import SlotBattlePage from './pages/SlotBattlePage/SlotBattlePage'
import CasesPage from './pages/Cases/CasesPage'
import CasePage from './pages/Cases/CasePage'
import BonusHuntsPage from './pages/BonusHunt/BonusHuntsPage'
import BonusHuntPage from './pages/BonusHunt/BonusHuntPage'
import ChallengesPage from './pages/Challenge/ChallengesPage'
import VIPRewardsPage from './pages/VIP/VIPRewardsPage'
import ClaimPointsPage from './pages/Profile/ClaimPointsPage'

import KenoPage from './pages/games/keno/page'
const CasinoPage = lazy(() => import('./pages/ReferralPage/CasinoPage')) 
const ToSPage = lazy(() => import('./pages/Policies/ToSPage')) 
const FAQPage = lazy(() => import('./pages/FAQPage/FAQPage')) 
const PrivacyPolicyPage = lazy(() => import('./pages/Policies/PrivacyPolicyPage')) 
const UserItemRedemptionModal = lazy(() => import('./components/Admin/Items/UserItemRedemptionModal')) 
const AdminIndexPage = lazy(() => import('./pages/AdminPage')) 
const UsersPage = lazy(() => import('./pages/AdminPage/User/UsersPage')) 
const AdminSlotBetPage = lazy(() => import('./pages/AdminPage/SlotBet/AdminSlotBetPage')) 
const ManageSlotBetPage = lazy(() => import('./pages/AdminPage/SlotBet/ManageSlotBetPage')) 
const AdminBonusHuntsPage = lazy(() => import('./pages/AdminPage/BonusHunt/BonusHuntsPage')) 
const AdminBonusHuntPage = lazy(() => import('./pages/AdminPage/BonusHunt/BonusHuntPage')) 

function App() {

  const [cookieConsent, setCookieConsent] = React.useState<boolean>(false)
  const profile = useSelector(selectProfile)
  const dispatch = useDispatch()
  let location = useLocation()
  const navigate = useNavigate()
  
  useEffect(() => {

    setCookieConsent((window.localStorage.getItem("CookieConsent")) ? true : false)

  }, [])

  useEffect(() => {

    const fetchUser = async () => {

      const data = await fetch(`${API_URL}/user`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) return false
          else return response.json()
        })
        .then((data) => {
          if (data) {
            dispatch(setProfile(data))
            if (data.messages) {
              dispatch(setMessages(data.messages))
            }
          }

          return data
        }).catch((err) => {
          return null
        })

        connectSocket()
        initSocketEvents(dispatch)
        initializeSocketListeners(dispatch, data)
    }

    if (!profile) {
      fetchUser()
    }

  }, [profile, dispatch])

  useEffect(() => {

    fetch(`${API_URL}/user/messages`, {
      method: 'GET',
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) return false
        else return response.json()
      })
      .then((data) => {
        if (data && !data.error) {
          dispatch(setMessages(data))

        }
      }).catch((err) => {
        return false
      })


  }, [dispatch])

  let background = location.state && location.state.background

  return ( <div className='main'>
      <>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />

      
          <Navbar />
          <Routes location={background || location}>

            <Route path="/profile" element={<ProfilePage />} />

            <Route path="/casino/*" element={<Suspense fallback={<Loading />}><CasinoPage /></Suspense>} />

            <Route path="/store" element={<StorePage />} />

            <Route path="/giveaways/*" element={<GiveawayPage />} />

            <Route path="/cases" element={<CasesPage />} />

            <Route path="/case/:id" element={<CasePage />} />

            <Route path="/highlights" element={<HighlightPage />} />

            <Route path="/leaderboard/*" element={<LeaderboardPage />} />

            <Route path="/faq" element={<Suspense fallback={<Loading />}><FAQPage /></Suspense>} />

            <Route path="/tos" element={<Suspense fallback={<Loading />}><ToSPage /></Suspense>} />

            <Route path="/privacy" element={<Suspense fallback={<Loading />}><PrivacyPolicyPage /></Suspense>} />

            <Route path="/slot-battles/*" element={<SlotBattlePage />} />
            
            <Route path="/hunts" element={<BonusHuntsPage />} />
            <Route path="/hunt/:id" element={<BonusHuntPage />} />

            <Route path="/raffle/:id" element={<RafflePage />} />

            <Route path="/promocode" element={<ClaimPromocode open={true} handleClose={() => navigate('/')} />} />

            <Route path="/challenges" element={<ChallengesPage />} />

            <Route path="/claimpoints" element={<ClaimPointsPage />} />  

          {hasPermission(profile, 'manage_users') && (
            <Route path="/admin/users" element={<Suspense fallback={<Loading />}><UsersPage /></Suspense>} />
          )}

          {hasPermission(profile, Permission.CREATE_SLOT_BET) && (
            <>
              <Route path="/admin/slotbattles" element={<Suspense fallback={<Loading />}><AdminSlotBetPage /></Suspense>} />
              <Route path="/admin/slotbattle/:id" element={<Suspense fallback={<Loading />}><ManageSlotBetPage /></Suspense>} />
            </>
          )}



          {hasPermission(profile, Permission.MANAGE_BONUS_HUNT) && (
            <>
              <Route path="/admin/hunt/:id" element={<Suspense fallback={<Loading />}><AdminBonusHuntPage /></Suspense>} />
              <Route path="/admin/hunts" element={<Suspense fallback={<Loading />}><AdminBonusHuntsPage /></Suspense>} />
            </>
          )}


            {hasPermission(profile, 'view_admin_page') && (
              <Route path="/admin/*" element={<Suspense fallback={<Loading />}><AdminIndexPage /></Suspense>}  />
            )}

            {(hasPermission(profile, 'view_redeemed_user_items') || hasPermission(profile, Permission.MANAGE_GIFTED_ITEM)) && (
              <Route path="/admin/useritems"  element={<Suspense fallback={<Loading />}><UserItemRedemptionModal open={true} handleClose={() => navigate('/')} /></Suspense>}  />
            )}

          <Route path="/battle/:id" element={<BattlePage />} />
          <Route path="/battles" element={<BattleListPage />} />
          {profile && (
            <Route path="/battles/history/my" element={<BattleHistoryPage />} />
          )}


          <Route path="/" element={<HomePage />} />

          <Route path="*" element={<NotFoundPage />} />

          </Routes>
          <CookieConsentBanner />
          <Footer />
          <ToastQuery />
          <ModalQuery />
          <ToastContainer theme={"dark"} />

          <BottomNavbar />

        </ThemeProvider>
      </>
    </div>
  )
}

export default App
